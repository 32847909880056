import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "button-language-guidelines"
    }}>{`Button Language Guidelines`}</h1>
    <p>{`Button labels (also known as Calls to Action or CTAs) should be as short and clear as possible, and
should describe the action the button performs.`}</p>
    <p>{`Try to limit button labels to three words or less. Using one or two words, if possible, is ideal.
When writing buttons, you can remove most prepositions and articles (a, an, the).`}</p>
    <p>{`You may consider using a noun after the verb if it adds clarity.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Discard Changes (`}<em parentName="li">{`what`}</em>{` do they discard?)`}</li>
      </ul>
    </Do>
    <p>{`Do not use “click” or “press” as part of a button label.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Submit`}</li>
        <li parentName="ul">{`Add Expense`}</li>
      </ul>
    </Do>
    <p>{`Use title case for button text.`}</p>
    <p><strong parentName="p">{`Buttons in Dialogs`}</strong></p>
    <p>{`If a question is asked in a dialog, use button labels that match the question. Keep in mind the
following guidelines as well:`}</p>
    <ul>
      <li parentName="ul">{`Don't use OK/Cancel to answer yes or no questions.`}</li>
      <li parentName="ul">{`Make sure you keep "your" and "my" consistent.`}</li>
      <li parentName="ul">{`Avoid saying "click" when referring to buttons (and other other UI elements).`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Open Messages`}</li>
        <li parentName="ul">{`Are you sure you want to delete this shift? Delete/Cancel`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Click Your Messages to Open My Messages`}</li>
        <li parentName="ul">{`Are you sure you want to delete this shift? OK/Cancel`}</li>
      </ul>
    </Dont>
    <p>{`See also: `}<a parentName="p" {...{
        "href": "/guidelines/content/language-and-style/person-pronouns"
      }}>{`My and Your`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      